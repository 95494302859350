import React from 'react';
import Aos from 'aos';
import { useEffect, useRef } from 'react';
import logo2 from '../assets/logo2.png';
import { Link } from 'react-router-dom';





const Header = ({theme}) => {
  const hederRef = useRef(null);
  const menuRef = useRef(null);

  const stickyHeaderFunc = () => {
    window.addEventListener('scroll', () => {
      if (
        document.body.scrollTop > 80||
        document.documentElement.scrollTop > 80
      ) {
        hederRef.current.classList.add('sticky_header');
      } else {
        hederRef.current.classList.remove('sticky_header');
      }
    });
  };

  useEffect(() => {
    stickyHeaderFunc();
    return window.removeEventListener('scroll', stickyHeaderFunc);
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  const toggleMenu = () => menuRef.current.classList.toggle('show_menu');
  return (
    <header
      ref={hederRef}
      className="flex justify-between items-center h-[12vh]
       bg-slate-50 sm:opacity-70"
    >
      <div className="container mx-auto">
        <div className="text-blue-800 text-md font-[500] m-10 ">
          {/*  logo   */}

          <div className="flex items-center justify-start gap-[3rem]">
            <div className="flex flex-1 items-center">
              <span
                className="w-[40px] h-[40px] 
        "
              >
                <img src={logo2} alt="" className="rounded-sm" />
              </span>

              <div className="leading-[20px]">
                <h2 className="text-xl font-[700] ml-5">
                  {' '}
                  <Link
                    className="hover:text-green-200
              hover:font-[500] md:text-[28px] text-[16px] 
              ease-in duration-300"
                    to={'/'}
                  >
                    jQ web developer
                  </Link>
                </h2>
              </div>
            </div>
            <div className="menu" ref={menuRef} onClick={toggleMenu}>
              <ul
                className="flex items-center 
              gap-10 "
              >
                <li>
                  <Link
                    className="font-[600] hover:text-green-200
              hover:font-[800]
              ease-in duration-300"
                    to={"/about"}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    className="font-[600] hover:text-green-200
              hover:font-[800]
              ease-in duration-300"
                    to={"/services"}
                  >
                    Service
                  </Link>
                </li>
                <li>
                  <Link
                    className="font-[600] hover:text-green-200
              hover:font-[800]
              ease-in duration-300"
                    to={"/projects-education"}
                  >
                    Projects
                  </Link>
                </li>
                <li>
                  <Link
                    className="font-[600] hover:text-green-200
              hover:font-[800]
              ease-in duration-300"
                    to={"/contact"}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex items-center justify-center">
              <Link to="https://wa.me/+573208435898">
                <button
                  className="bg-green-500 text-white font-[300] flex items-center justify-center
                gap-1 md:gap-0 hover:bg-green-600 ease-in md:h-[20px] md:w-[25vh]
                duration-300 p-5 h-[2rem] w-[2vh] display-none rounded-3xl mr-1 invisible md:visible "
                >
                  <i class="ri-whatsapp-line md:text-[38px] text-[30px] bg-green-500 rounded-full  md:bg-transparent visible"></i>
                  Let's talk
                </button>
                </Link>
                <span
                onClick={toggleMenu}
                className="text-2xl md:hidden cursor-pointer text-blue-600 hover:font-[900] ease-in duration-200 "
              >
                <i class="ri-menu-line"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
