import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from './components/Header';
import ProjectsEducation from './components/ProjectsEducation';
import Education from './components/Education';
import Home from './pages/Home';
import { ToastContainer } from 'react-toastify';
import Services from './components/Services';
import Chatbot from './components/Chatbot';
import { createContext, useState } from 'react';
import ReactSwitch from 'react-switch';


export const ThemeContext = createContext(null);

function App() {
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme((curr) => (curr === 'light' ? 'dark' : 'light'));
  };

  return (
    <>
      <Router>
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
          <div className="main" id={theme}>
             
              <Header/>
             
              <Routes>
              <Route path="/" element={<Home />} />
               <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route
                path="/projects-education"
                element={<ProjectsEducation />}
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/education" element={<Education />} />
              <Route path="/chatbot" element={<Chatbot />} />
            </Routes>
            <div className='theme-color'>
            <label className='label-color'>{theme === 'light' ? 'Light Mode' : 'Dark Mode'}</label>
            <ReactSwitch ReactSwitch  onChange={toggleTheme} checked={theme === "dark"}/>
            </div>
            <Footer />
          </div>
        </ThemeContext.Provider>
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
export default App;
