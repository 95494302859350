import React, { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {


  const refForm = useRef();
  const handleSubmit = (event) => {
      event.preventDefault();

    const serviceId = 'service_gd9rbgd';
    const templateId = 'template_81gikmf';

    const appiKey = 'lBet7sL7rP2w8NueA';
    emailjs.sendForm(serviceId, templateId, refForm.current, appiKey).then(
      (result) => {
        console.log(result.text);
        toast.success('Message sent');
        refForm.current.reset();
      },
      (error) => {
        console.log(error);
        toast.error('Oops something went wrong');
        
      }
    );
  };

  return (
   
    <section id="contact" className="pb-16">
      <div className="container mx-auto">
         <h2 className="m-10 text-blue-700 text-[2rem] font-[700]">
          Get in touch
        </h2>
        <div className="md:flex justify-center items-center my-10">
          <div className="w-full md:w-1/2 h-[300px] sm:h-[550px]">
            <iframe
              title="google-maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d254508.39472062947!2d-74.27262568839377!3d4.648620644244103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9bfd2da6cb29%3A0x239d635520a33914!2zQm9nb3TDoQ!5e0!3m2!1ses-419!2sco!4v1696952200797!5m2!1ses-419!2sco"
              className="border-0 w-full h-full"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div
            className="w-full mt-8 md:mt-0 md:w-1/2 sm:h-[550px] lg:flex items-center bg-cyan-400
          px-4 lg:px-8 py-8"
          >
                <form className="w-full" ref={refForm} onSubmit={handleSubmit}>
              <div className="mb-5">
                <label
                  htmlFor=""
                  className="text-blue-800 font-[600] text-[36px]"
                >
                  Contact me
                </label>
                <input
                  type="text"
                  required
                  placeholder="Enter your Name"
                  name="name"
                  className="w-full p-3 focus:outline-none rounded-[5px]"
                 
                />
                
              </div>
              <div className="mb-5">
                <input
                  type="email"
                  required
                  name="email"
                  placeholder="Enter your Email"
                  className="w-full p-3 focus:outline-none rounded-[5px]"
                  
                />
               
              </div>
              <div className="mb-5">
                <input
                  type="text"
                  required
                  name="subject"
                  placeholder="Email subject"
                  className="w-full p-3 focus:outline-none rounded-[5px]"
                  
                />
              </div>
              
              <div className="mb-5">
                <textarea
                  type="text"
                  required =' '
                  rows={3}
                  name="message"
                  placeholder="Send Message"
                  className="w-full p-3 focus:outline-none rounded-[5px]"
                
                />
               
              </div>
              <button
                className="w-full p-3 focus:outline-none rounded-[5px]
               bg-blue-600	 hover:bg-green-600 text-center ease-linear duration-150 text-blue-200"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
    
  );
};

export default Contact;
