import React from 'react';
import { Link } from 'react-router-dom';
import { GrCertificate } from 'react-icons/gr';
import { GiDiploma } from 'react-icons/gi';

export default function ProjectsEducation() {
  return (
    <>
      <div className="max-w-[1240px] lg:mx-auto rounded-lg text-blue-400 shadow-lg bg-withe leading-10 ">
        <ul className=" grid sm:grid-cols-2 lg:grid-col-4 gap-4 p-6 m-6 text-justify">
          <div className="m-2">
            <li className="font-bold text-lg">2004-2010</li>
            <li className="font-semibold text-xl mb-2">Zootecnia</li>
            <li className="capitalize font-semibold mb-4">
              University of the Salle Bogotá
            </li>
            <li className="font-bold text-lg">04 - 2021 / 12 - 2021</li>
            <li className="text-lg font-semibold">Diplomas in:</li>
            <li className="flex">
            <i class="ri-arrow-right-fill"></i> Web
              Application Development
            </li>
            <li className="flex ">
            <i class="ri-arrow-right-fill"></i>
              Software Development
            </li>
            <li className="flex text-justify line-clamp-3">
            <i class="ri-arrow-right-fill"></i>
              Basic Programming and Fundamentals of Programming with certificate
              in Academic Knowledge in Skills in programming
            </li>
            <li className="font-semibold mt-4 flex">
              <GiDiploma className="h-8 w-6 mr-2" />
              MINTIC and the University of the Norte Barranquilla
            </li>
          </div>
          <div className="m-2">
            <li className="font-bold text-lg ">07-2022 / 09-2022</li>
            <li className="font-semibold">
              Beginner Certificate in G3 – ONE programming
            </li>
            <li className="flex">
            <i class="ri-arrow-right-fill"></i>
              Training: Programming logic, HTML5 and CSS3, Git and GitHub
            </li>
            <li className="font-bold text-lg mt-4">09-2022 / 11-2022</li>
            <li className="font-semibold">FRONT-END</li>
            <li className="flex">
            <i class="ri-arrow-right-fill"></i>
              Training : Flexbox, CSS Grid, Responsive Layouts, JavaScript.
            </li>
            <li className="font-bold text-lg mt-4">11-2022 / 02-2023</li>
            <li className="font-semibold">BACK-END</li>
            <li className="flex">
            <i class="ri-arrow-right-fill"></i>
              Java JRE and JDK, Java OO, Mastering the Collections ,Java
              Exceptions, Java Polymorphism.
            </li>
            <li className="flex">
            <i class="ri-arrow-right-fill"></i>
              Java and java.Lang, Java and java.useful and Java and JDBC
            </li>

            <li className="font-semibold mt-2 flex items-center">
              <Link
                to={
                  'https://app.aluracursos.com/user/mayefa10/fullCertificate/9fae21b62447803735acee1cff85c856'
                }
              >
                <GrCertificate className="h-8 w-6 mr-2" />
              </Link>
              alura LATAM in union with ONE oracle Next Education
            </li>
          </div>
        </ul>
      </div>
    </>
  );
}
