import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from 'swiper';
import 'swiper/css/bundle';
import imagen4 from '../assets/imagen-4.jpg';
import imagenDos from '../assets/imagen-2.jpg';
import imagenFive from '../assets/imageFive.jpg';


export default function Slider() {
  SwiperCore.use([Autoplay, Navigation, Pagination]);
  return (
    <>
      <div className="">
        <Swiper
          slidesPerView={1}
          navigation
          pagination={{ type: 'progressbar' }}
          effect="fade"
          modules={[EffectFade, Navigation]}
          autoplay={{ delay: 3000 }}
          className="md:h-[600px]"
        >
          <SwiperSlide
            className="relative 
            w-full 
            overflow-hidden
            bg-no-repeat
            bg-center"
          >
            <div className="absolute  text-slate-400 ">
              <h1 className=" md:text-[40px] m-10 text-[20px]">
                jQ web developer
              </h1>
              <p className=" m-10 md:font-[700] md:text-[4rem] text-[1.5rem] font-[500] mt-0 md:mt-28 lg:mt-0">
                We create your personalized website
              </p>
              <h2 className="ml-10 md:my-60 md:text-[20px] text-[10px] md:mt-28 mt-0 lg:mt-60">
                {' '}
                We help you make your business idea a reality
              </h2>
            </div>
            <img
              src={imagen4}
              alt="imagen-4"
              className=""
            />
          </SwiperSlide>
          <SwiperSlide
            className="relative 
            w-full
            overflow-hidden
            bg-no-repeat
            bg-cover
            bg-center"
          >
            <div className="absolute text-slate-400 ">
              <h1 className=" md:text-[40px] m-10 text-[20px]">
                jQ web developer
              </h1>
              <p className=" m-10 md:font-[700] md:text-[4rem] text-[1.5rem] font-[500] mt-0 md:mt-28 lg:mt-0">
                Digital solutions at your service
              </p>
              <h2
                className="ml-10 md:my-60 md:text-[20px] text-[10px] md:mt-28 lg:mt-60 ">
              
                {' '} 
                We help you make your business idea a reality
              </h2>
            </div>
            <img
              src={imagenDos}
              alt="imagenDos"
              className=""
            />
          </SwiperSlide>
          <SwiperSlide
            className="relative 
            w-full 
            overflow-hidden
            bg-no-repeat
            bg-cover
            bg-center"
          >
            <div className="absolute text-slate-400">
              <h1 className=" md:text-[40px] m-10 text-[20px]">
                jQ web developer
              </h1>
              <p className="m-10 md:font-[700] md:text-[4rem] text-[1.5rem] font-[500] -mt-6 md:mt-28 lg:mt-0">
              Let's make your website and plant a tree for the planet
              </p>
              <h2 className="ml-10 md:mt-[9rem] md:text-[20px] text-[10px] mt-10">
                {' '}
                We help you make your business idea a reality
              </h2>
            </div>
            <img
              src={imagenFive}
              alt="imagen-5"
              className=" "
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
