import React from 'react';
import picture3 from '../assets/avatar.png';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';

function About() {
  return (
    <>
      <section className="pt-0 mx-10 my-10" id="about">
        <div className="container">
          <div className="md:flex items-center justify-between flex-col md:flex-row">
            <div className="w-full">
              <h5
                className="text-blue-600 font-[700]
          text-[20px]"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Hello welcome
              </h5>
              <h1
                className="text-blue-600 font-[500]
          text-[2rem] sm:text-[40px]
          leading-9 sm:leading-[46px] mt-2"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                I´m July <br /> web developer
              </h1>
              <div
                className="flex items-center gap-6 mt-7"
                data-aos="fade-up"
                data-aos-duration="1800"
                data-aos-delay="200"
              >
                <Link to="mailto:quifa10@gmail.com">
                  <button
                    className="bg-green-300 text-blue-500 font-[700] flex items-center
                gap-2  hover:bg-green-500 ease-in
                duration-300 py-2 px-2 h-8 rounded-[8px]"
                  >
                    <i class="ri-mail-line"></i>
                    Hire me
                  </button>
                </Link>
              </div>
              <p
                className="flex gap-2 mt-8 text-blue-500 font-[500]
                text-[16px] leading-8
                sm:pl-14 sm:pr-10"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <span>
                  <i class="ri-apps-line"></i>
                </span>
                Proactive web developer zootechnician with the ability to
                develop attractive and elegant web pages according to the
                specifications and requirements of each client. Organized and
                experienced in project planning, development and scheduling,
                design improvement and code verification. Web development and
                teamwork skills, constant learning ability according to web
                industry standards.
              </p>
              <div className="flex items-center gap-9 mt-14">
                <span className="text-[15px] font-[600] text-blue-400">
                  Social Networks:
                </span>
                <span>
                  <Link
                    className="text-[20px] font-[600]"
                    to="https://github.com/mayefa10"
                  >
                    <i class="ri-github-fill"></i>
                  </Link>
                </span>
                <span>
                  <Link className="text-[20px] font-[600]" to="https://www.instagram.com/jQweb/">
                    <i class="ri-instagram-line"></i>
                  </Link>
                </span>
                <span>
                  <Link
                    className="text-[20px] font-[600] text-blue-800"
                    to="https://www.linkedin.com/in/july-quintero-64115427/"
                  >
                    <i class="ri-linkedin-box-fill"></i>
                  </Link>
                </span>
              </div>
            </div>

            <div className="basic-1/3 mt-12 sm:mt-0 flex flex-col items-center justify-center ">
              <h2
                className="text-blue-700 md:text-xl text-md mb-16 font-[600]"
                data-aos="zoom-in-up"
                data-aos-duration="1500"
              >
                <i class="ri-earth-fill mr-2"></i>
                Let's make technology something good for the world
              </h2>
              <figure className="flex items-center justify-center">
                <img
                  src={picture3}
                  alt="avatar"
                  className="w-3/6 rounded-t-full"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                />
              </figure>
            </div>
            <div
              className="md:basic-1/5 flex justify-between text-center flex-wrap gap-3 md:mt-0 
            md:flex-col md:justify-end md:text-end"
            >
              <div className="flex flex-col items-center gap-2 text-center">
                <h2 className="text-blue-500 font-[700] text-[37px]">
                  <CountUp start={0} end={3} duration={2} suffix="+" />
                </h2>
                <h4 className="text-blue-500 font-[700] text-[10px]">
                  Years experience
                </h4>
              </div>
              <div className="flex flex-col items-center gap-2 text-center">
                <h2 className="text-blue-500 font-[700] text-[37px]">
                  <CountUp start={0} end={100} duration={2} suffix="%" />
                </h2>
                <h4 className="text-blue-500 font-[700] text-[10px]">
                  Success rate
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default About;
