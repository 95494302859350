import React from 'react';
import icon from '../assets/tree-fill.png';
import mac from '../assets/mac.jpg';
import icon2 from '../assets/icon.jpg';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <section id="services">
      <div className="container lg:pt-4 mt-20 md:mt-10 mx-auto ">
        <div className="text-center">
          <h2 className="text-blue-500 font-[800] text-[2.8rem] mb-5">
            what do i help you
          </h2>
          <p
            className="lg:max-w-[600px] lg:mx-auto text-blue-600 font-[500] text-[16px]
          leading-7
          "
          >
            For each website we create, we will plant five native trees as a
            symbol of your company with climate change, and so that together we
            contribute to the well-being of this planet from technology
          </p>
        </div>
        <ul
          className="md:flex p-8 items-center 
          space-y-12
          md:space-y-0  
          md:space-x-8
          m-2"
        >
          
          <li
            className=" 
    whitespace-nowrap
    relative bg-gray-100 
    flex flex-col 
    justify-between 
    items-center
    shadow-sm
    hover:shadow-xl
    rounded-md
    overflow-hidden
    transition-shadow
    duration-150
    m-[10px]"
          >
            <div
                          data-aos="fade-down"
                          data-aos-duration="1200"
                          className="bg-white shadow group hover:bg-slate-200 cursor-pointer ease-in duration-150"
                        >
            <img
              src={mac}
              alt=""
              className="p-8
       object-cover
        hover:scale-105
        transition-scale
        duration-200
        ease-in"
              loading=
              "lazy"
            />
             <div
              className=" flex flex-col text-center gap-2
              pb-6 mx-10
              
          "
            >
               
             <h3 className="text-blue-500 font-[600] mb-3 group-hover:text-blue-700 group-hover:font-[800] text-xl">
              Frontend Development
            </h3>
           
           
            <p className="text-slate-700  md:text-[15px] text-[11px] leading-8">
              <i class="ri-arrow-right-fill"></i>UI development for websites and
              apps <br /> <i class="ri-arrow-right-fill"></i>
              Ecommerce website development <br />{' '}
              <i class="ri-arrow-right-fill"></i> Responsive design of your
              website: <br/>adaptable to tablet, computer and mobile <br />{' '}
              <i class="ri-arrow-right-fill"></i>Fully customized web design
              <br /> <i class="ri-arrow-right-fill"></i>
              Pages and sections of your website tailored to your needs
            </p>
            
            </div>
            </div>
          </li>
          <li
            className=" 
    whitespace-nowrap
    relative bg-gray-100 
    flex flex-col 
    justify-between 
    items-center
    shadow-sm
    hover:shadow-xl
    rounded-md
    overflow-hidden
    transition-shadow
    duration-150
    m-[10px]"
          >
             <div
                          data-aos="fade-down"
                          data-aos-duration="1200"
                          className="bg-white shadow group hover:bg-slate-200 cursor-pointer ease-in duration-150"
                        >
            <img
              src={icon2}
              alt=""
              className=" p-8
        object-cover
        hover:scale-105
        transition-scale
        duration-200
        ease-in"
              loading="lazy"
            />
            <div className=" flex flex-col text-center gap-2
              pb-6 mx-10 
              
          ">
            <h3 className="text-blue-500 font-[600] mb-3 group-hover:text-blue-700 group-hover:font-[800] text-xl">
              Backend Development
            </h3>
            <p className="text-slate-700 md:text-[15px] text-[11px] leading-8">
              <i class="ri-arrow-right-fill"></i>Managing databases, storage
              technologies, <br/>and API technologies so that applications and<br/>
              systems communicate with each other
              <br /> <i class="ri-arrow-right-fill"></i>Domain and hosting
              <br /> <i class="ri-arrow-right-fill"></i>
              Chat buttons on Whatsapp, Telegram and sending mail
              <br /> <i class="ri-arrow-right-fill"></i>
              Contact Form
            </p>
            </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Services;
