import React, { useEffect } from 'react';

const Chatbot = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_SRC;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.botpressWebChat.init({
        botId: process.env.REACT_APP_BOT_ID,
        hostUrl: process.env.REACT_APP_HOST_URL,
        messagingUrl: process.env.REACT_APP_MESSAGIN_URL,
        clientId: process.env.REACT_APP_CLIENT_ID,
        botName: 'jQ web developer',
        phoneNumber: '+57 320 843 5898',
        emailAddress: 'quifa10@gmail.com',
        useSessionStorage: true,
        showBotInfoPage: true,
        enableConversationDeletion: true,
        showPoweredBy: true,
      });
    };
  }, []);

  return <div id="webchat"></div>;
};

export default Chatbot;
