import React from 'react';
import { Link } from 'react-router-dom';
import icon2 from '../assets/logo2.png';
import Chatbot from './Chatbot';

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-cyan-400 pt-12 mt-10 ">
      <div className="container mx-auto">
        <div className="sm:flex items-center justify-between md:gap-8 mx-10 p-2">
          <div className="w-full sm:w-1/2">
            <h2 className="text-[26px] leading-7 text-blue-700 font-[600] mb-5 md:text-[2rem]">
              You want an impressive website with an environmental sense
            </h2>
            <Link to="https://wa.me/+573208435898">
              <button
                className="bg-green-500 text-white font-400] flex items-center justify-center
                gap-2  hover:bg-green-600 ease-in
                duration-300 py-5 px-5 h-[50px] w-[30vh] rounded-3xl"
              >
                <i class="ri-whatsapp-line text-[40px]"></i>
                Let's talk
              </button>
            </Link>
            <div className="mt-2 text-white">
              <h3 className="text-[20px] font-[600]">
                <i class="ri-time-line mr-2 text-black"></i>
                Office hours
              </h3>
              <h4 className="">Monday to Friday from 7:00 am to 17:00 pm</h4>
              <h4 className="">Saturday, Sunday and holidays closed</h4>
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <p className="text-blue-700 leading-7 mt-4 sm:mt-0">
              I put my knowledge in web design at your service so that together
              we can create the best website for your company or business idea.
            </p>
            <div className="flex items-center gap-2 flex-wrap md:gap-8 mt-10">
              <span className="text-blue-700 text-[15px]">Contact me</span>
              <span className="md:w-[50px] md:h-[50px] w-[30px] h-[30px]  bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center flex items-center justify-center">
                <Link
                  className="text-white text-[30px]"
                  to="https://t.me/jQwebdeveloper"
                >
                  <i class="ri-telegram-line"></i>
                </Link>
              </span>
              <span className="md:w-[50px] md:h-[50px] w-[30px] h-[30px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center flex items-center justify-center">
                <Link className="text-white font-[500] text-[30px]" to="https://www.instagram.com/jQweb/">
                  <i class="ri-instagram-line "></i>
                </Link>
              </span>
              <span className="md:w-[50px] md:h-[50px] w-[30px] h-[30px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center flex items-center justify-center">
                <Link
                  className="text-white text-[30px]"
                  to="https://www.linkedin.com/in/july-quintero-64115427/"
                >
                  <i class="ri-linkedin-box-fill"></i>
                </Link>
              </span>
              <h1 className="cursor-pointer text-center">
                <Link
                  className="text-white font-[500] text-[20px]"
                  to="mailto:quifa10@gmail.com"
                >
                  quifa10@gmail.com
                </Link>
              </h1>
            </div>
          </div>
        </div>
        <div>
          <ul
            className="flex items-center justify-center 
              md:gap-10 gap-7  mt-10"
          >
            <li >
              <Link className="font-[600] text-blue-900" to="/about">
                About
              </Link>
            </li>
            <li>
              <Link className="font-[600] text-blue-900" to="/services">
                Service
              </Link>
            </li>
            <li>
              <Link
                className="font-[600] text-blue-900"
                to="/projects-education"
              >
                Projects
              </Link>
            </li>
            <li>
              <Link className="font-[600] text-blue-900" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-[#1b1e29] py-5 mt-14">
        <div className="container">
          <div className="flex items-center md:justify-center justify-between">
            <div className="hidden sm:block">
              <div className="flex items-center gap-[10px]">
                <img
                  src={icon2}
                  alt="icon2"
                  className="w-[35px] h-[35px] rounded-sm flex justify-center items-center"
                />
                <div className="leading-[20px]">
                  <h2 className="text-gray-300 font-[500] text-[18px]">July</h2>
                  <p className="text-gray-500 text-[14px] font-[500] mr-12">
                    Personal
                  </p>
                </div>
              </div>
            </div>
            <Chatbot/>
            <p className="text-gray-300 text-[16px] text-center">
              © {year} Copyright developed by jQ july - All right reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
