import React from 'react';
import data from '../assets/data/portfolioDta';
import Modal from './Modal';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function ProjectsEducation() {

  const [showModal, setShowModal] = useState(false);
  const [activeId, setActiveId] = useState(null);
  
  const shoWModalHandler = id =>{
    setShowModal(true)
    setActiveId(id)
  }
  return (

     <section id="portfolioItems">
      <div className="container mx-auto">
        <div className="flex m-10  items-center justify-between flex-wrap">
          <div className="mb-7 sm:mb-0">
            <h3 className="text-blue-700 text-[2rem] font-[700]">
              My recent projects
            </h3>
          </div>
          <div className="flex gap-3">
            <Link to='/education'>
            <button
              className="text-blue-700 border border-solid border-blue-800
          rounded-[8px] py-4 px-4"
            >
              Educations
            </button>
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-center gap-6 flex-wrap mt-12 mx-10">
          {data?.map((portfolioItems, index) => (
            <div
              className="group max-w-full sm:w-[48.5%] md:w-[31.8%] lg:w-[32.2%] relative z-[1] p-10"
              data-aos="fade-zoom-in"
              data-aos-delay="50"
              data-aos-duration="1000"
            >
              <figure>
                <img
                  src={portfolioItems.imgUrl}
                  alt=""
                  className="rounded-[8px]"
                />
              </figure>
              <div
                className="w-full h-full bg-blue-200 bg-opacity-40 absolute top-0
              left-0 z-[5] hidden group-hover:block"
              >
                <div
                  className="w-full h-full flex justify-center 
                items-center"
                >
                  <button
                    className="text-green-300 bg-green-500
                  hover:bg-blue-500 hover:font-[800] py-2 px-4 rounded-[8px]
                  ease-in duration-150"
                  onClick = {()=>shoWModalHandler(portfolioItems.id)}
                  >
                    See details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        
      </div>
      {
        showModal && <Modal setShowModal={setShowModal} activeId ={activeId}/>
      }
    </section>
  );
}
