import imagen1 from '../proyectOneM_React.png';
import imagen2 from '../game-ecommerce.png';
import imagen5 from '../movie_catalog.png';
import imagen6 from '../portfolio_react.png';


const portfolioItems = [
  {
    id: '01',
    imgUrl: imagen1,
    title: 'Project Clone Realtor',
    description: 'Practice project using React, javaScript, tailwindCss, and components how slider and toasts and connection with data base how firebase',
    technologies: ['React', 'TailwindCss', 'NodeJS', 'Firebase'],
    siteUrl: 'https://proyect-one-react.vercel.app/',
  },
  {
    id: '02',
    imgUrl: imagen2,
    title: 'Ecommerce Games',
    description: 'Video game sales website, with home pages, product pages, categories and product description. Connection to non-relational database MongoDb',
    technologies: ['React', 'Material icons', 'NodeJS', 'MongoDb', 'postman'],
    siteUrl: 'https://ecommerce-react-rust.vercel.app/',
  },
  
  {
    id: '03',
    imgUrl: imagen5,
    title: 'Movie catalog',
    description: 'Practices apis using ReactJs ',
    technologies: ['React', 'Bootstrap', 'NodeJS','api'],
    siteUrl: 'https://movie-catalog-pi.vercel.app/',
  },
  {
    id: '04',
    imgUrl: imagen6,
    title: 'Portfolio',
    description: 'Web portfolio using React Js, creating components and routes with react-router-dom, CSS and responsive design using tailwind',
    technologies: ['React', 'Tailwind CSS', 'NodeJS', 'api'],
    siteUrl: 'https://web-porfolio.onrender.com/',
  },
];
export default portfolioItems;