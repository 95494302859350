import About from '../components/About';
import Contact from '../components/Contact';
import ProjectsEducation from '../components/ProjectsEducation';
import Slider from '../components/Slider';
import Services from '../components/Services';





export default function Home() {
  
  
  return (
    
    <>
    <Slider  />
 <div className="max-w-6xl mx-auto pt-4 space-y-6">
    <About/>
    <Services/>
    <ProjectsEducation/>
    <Contact/>
    </div>
    </>
  )
}
