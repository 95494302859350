import React from 'react';
import portfolioItems from '../assets/data/portfolioDta';
import { Link } from 'react-router-dom';

const Modal = ({ activeId, setShowModal }) => {
  const portfolioItem = portfolioItems.find(
    (portfolioItem) => portfolioItem.id === activeId
  );
  return (
    <div className="w-full h-full fixed top-9 left-0 z-10 bg-gray-100 bg-opacity-40">
      <div className="w-9/12 max-w-[300px] md:max-w-[400px] md:w-full relative top-1/2  left-1/2 bg-zinc-400 rounded-[10px] transform -translate-x-1/2 md:-translate-y-60 -translate-y-36 p-4">
        <div>
          <figure>
            <img src={portfolioItem.imgUrl} alt="" className="rounded-[8px]" />
          </figure>
          <div>
            <h2 className="md:text-2xl text-xl text-blue-900 font-[800] md:my-3 my-1">
              {portfolioItem.title}
            </h2>
            <p className="md:text-[14px] text-[11px] text-blue-700 font-[600] leading-0 md:leading-7">
              {portfolioItem.description}
            </p>
            <div className="  flex md:mt-3 mt-1 items-center gap-3 flex-wrap">
              <h4 className=" text-blue-700  font-[700]">
                Technologies:
              </h4>{portfolioItem.technologies.map((item, index) => (
              <span
                key={index}
                className="bg-green-500 text-white py-1 px-1 rounded-[5px] text-[12px] leading-0 "
              >
                {item}
              </span>
            ))}
          </div>
            </div>
            
          <Link to ={portfolioItem.siteUrl}>
            <button
              className="text-green-300 md:bg-green-500 bg-teal-700
                  hover:bg-blue-500 hover:font-[800] py-2 px-4 rounded-[8px]
                  ease-in duration-150 md:my-6 my-1.5 "
            >
              Live Site
            </button>
          </Link>
          <button 
          onClick = {()=>setShowModal(false)}
          className="w-[1.8rem] h-[1.8rem] bg-gray-200 absolute top-[1.7rem] right-[1.7rem] text-[25px] flex items-center justify-center rounded-[3px]
          leading-0 cursor-pointer hover:bg-gray-300 hover:font-[800] py-2 px-4 
          ease-in duration-150 ">&times;</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
